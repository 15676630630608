<script src="https://js.stripe.com/v3/"></script>
<template>
  <div class="container" id="directPayment" v-if="paymentInitData && paymentInitData.status">
    <form id="payment-form">
      <div id="link-authentication-element">
        <!--Stripe.js injects the Link Authentication Element-->
      </div>
      <div id="payment-element">
        <!--Stripe.js injects the Payment Element-->
      </div>
      <button id="submit" v-if="paymentInitData && paymentInitData.clientSecret">
        <div class="spinner hidden" id="spinner"></div>
        <span id="button-text">Pay now ({{ currency }} {{ payAmount }})</span>
      </button>
      <div id="payment-message" class="hidden"></div>
    </form>
  </div>
  <div v-else>
    {{ paymentInitData.message }}
  </div>
</template>

<script>

export default {
  name: 'stripe-checkout',
  props:["pk","currency","customer","description","redirectionPath","microservicePath","pgAccountId","txnAcId","payAmount"],
  data () {
    return {
      stripe:null,
      emailAddress:'',
      elements:null,
      paymentInitData:{},
      // totalAmount:0,
      paymentStatus:{}
    }
  },
  async mounted() {
    if(this.pk)
    {
      this.stripe = Stripe(this.pk);
      this.paymentInit();
      if(this.customer && this.customer.email)
      {
        this.emailAddress= this.customer.email;
      }    
    }
    else
    {
      alert("Invalid account details");
    }
  },
  methods: {
    showMessage(messageText) {
  const messageContainer = document.querySelector("#payment-message");

  messageContainer.classList.remove("hidden");
  messageContainer.textContent = messageText;

  setTimeout(function () {
    messageContainer.classList.add("hidden");
    messageText.textContent = "";
  }, 4000);
},

// Show a spinner on payment submission
setLoading(isLoading) {
  if (isLoading) {
    // Disable the button and show a spinner
    document.querySelector("#submit").disabled = true;
    document.querySelector("#spinner").classList.remove("hidden");
    document.querySelector("#button-text").classList.add("hidden");
  } else {
    document.querySelector("#submit").disabled = false;
    document.querySelector("#spinner").classList.add("hidden");
    document.querySelector("#button-text").classList.remove("hidden");
  }
},
  async handleSubmit(e) {
  e.preventDefault();
  this.setLoading(true);

  const { error } = await this.stripe.confirmPayment({
    elements:this.elements,
    confirmParams: {
      // Make sure to change this to your payment completion page
      return_url: this.redirectionPath,
      receipt_email: this.emailAddress,
    },
  });

  if (error.type === "card_error" || error.type === "validation_error") {
    this.showMessage(error.message);
  } else {
    this.showMessage("An unexpected error occurred.");
  }
  this.setLoading(false);
},
   async paymentInit(){
    try{
      console.log("Pay for stripe",this.payAmount,this.payAmount*100);
      let reqBody={
        currency:this.currency,
        amount: Math.ceil(parseFloat(this.payAmount)*100),
        description:JSON.stringify(this.description)
      };
      if(this.customer && this.customer.email && this.customer.name)
      {
        reqBody["customer"]={
                  email: this.customer.email,
                  name: this.customer.name
                }
      }
      console.log("Final Stripe Intent",reqBody)
    const response = await fetch(`${this.microservicePath}/stripe/create-payment-intent?pgAccountId=${this.pgAccountId}&txnAcId=${this.txnAcId}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody)
  });
  console.log("response",response)
  this.paymentInitData= await response.json();
  console.log("paymentInitData",this.paymentInitData)
  if(this.paymentInitData.status)
  {
  const appearance = {
    theme: 'stripe',
  };
  this.elements = await this.stripe.elements({ appearance, clientSecret:this.paymentInitData.clientSecret });
  const paymentElementOptions = {
    layout: "tabs",
  };
  const paymentElement =await  this.elements.create("payment", paymentElementOptions);
  paymentElement.mount("#payment-element");
  
  document.querySelector("#payment-form").addEventListener("submit", this.handleSubmit);
}
else{
  alert(this.paymentInitData.message)
}
  }
  catch(error)
  {
    console.log(error)
  }
  }
}
}
</script>

<style lang="scss" scoped>
#directPayment {

  form {
    width: 100%;
    //min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 0.5rem;
  }

  .hidden {
    display: none;
  }

  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }

  #payment-element {
    margin-bottom: 24px;
  }

  /* Buttons and links */
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }

  button:hover {
    filter: contrast(115%);
  }

  button:disabled {
    opacity: 0.5;
    cursor: default;
  }

  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }

  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }

  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }

  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
      min-width: initial;
    }
  }
}

//button {
//  background-color: $app-bgm;
//}
</style>


<!-- npm install stripe --save -->

<!-- 
const stripe = require('stripe')('sk_test_xxxxxxxxxxxxxxxxxxxxx');
let user = await stripe.customers.create({ email: 'xxxx@gmail.com', payment_method: token });
await stripe.paymentIntents.create({
  amount: 25 * 100, // $25
  currency: 'usd',
  customer: user
}) -->
